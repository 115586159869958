import React from "react";

const GridPage = () => {
  return (
    <div className="w-full ">
      <img src="image.png" className="mx-auto pt-12"/>
      <p className="text-center -mt-8 text-gray-600 font-medium text-base">The Grid View for sensor will be coming soon</p>
    </div>
  );
};

export default GridPage;
